export const GET_TRIPS = 'GET_TRIPS'
export const GET_TRIP = 'GET_TRIP'
export const GET_USER_TRIPS = 'GET_USER_TRIPS'
export const DELETE_USER_TRIP = 'DELETE_USER_TRIP'
export const TRIPS_ERROR = 'TRIPS_ERROR'
export const UPDATE_USER_TRIP = 'UPDATE_USER_TRIP'
export const GET_USER_WISHLIST = 'GET_USER_WISHLIST'
export const GET_USER_PAST_TRIPS = 'GET_USER_PAST_TRIPS'
export const GET_USER_REQUESTED = 'GET_USER_REQUESTED'
export const CREATE_TRIP = 'CREATE_TRIP'
export const LEAVE_TRIP = 'LEAVE_TRIP'
export const REQUEST_JOIN_TRIP = 'REQUEST_JOIN_TRIP'
export const GET_REQUESTED_MEMBERS = 'GET_REQUESTED_MEMBERS'
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR'
export const ADD_MEMBER_TRIP = 'ADD_MEMBER_TRIP'
export const REMOVE_MEMBER_AS_HOST = 'REMOVE_MEMBER_AS_HOST'
export const DECLINE_REQUEST_AS_HOST = 'DECLINE_REQUEST_AS_HOST'
export const ADD_WISHLIST_TRIP = 'ADD_WISHLIST_TRIP'
export const REMOVE_WISHLIST_TRIP = 'REMOVE_WISHLIST_TRIP'
export const SET_SEARCH_FORM_SUCCESS = 'SET_SEARCH_FORM_SUCCESS'
export const DEFAULT_STATE = 'DEFAULT_STATE'
