export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const LOGOUT = 'LOGOUT'

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const USER_LOADED = 'USER_LOADED'
export const USER_LOAD_ERROR = 'USER_LOAD_ERROR'

export const FORGET_PASS_SUCCESS = 'FORGET_PASS_SUCCESS'
export const FORGET_PASS_FAIL = 'FORGET_PASS_FAIL'

export const RESET_PASS_SUCCESS = 'RESET_PASS_SUCCESS'
export const RESET_PASS_FAIL = 'RESET_PASS_FAIL'

export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_FAIL = 'VERIFY_OTP_FAIL'

export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS'
export const EDIT_SECONDARY_KEY = 'EDIT_SECONDARY_KEY'
export const RESEND_OTP_FAIL = 'RESEND_OTP_FAIL'

