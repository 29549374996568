import React, { memo } from 'react'
import { Container, Slider, Card, Badge, BadgeText, SliderHeading, CardContainer } from './SlidingSection.styled'
import { connect } from 'react-redux'
import { computeDateAndTimeUntilNowInString } from '../../utils/DateUtils'

const cardData = [
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '12 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '10 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
  {
    background:
      'https://previews.123rf.com/images/rawpixel/rawpixel1704/rawpixel170416831/75837080-mountain-sky-clouds-scenery-landscape-beautiful.jpg',
    text: '5 Days to go',
  },
]

const mapStateToProps = (state) => ({
  trips: state.tripReducer.trips,

  searchForm: state.tripReducer.searchForm,
})

const SlidingSection = ({ trips }) => {
  return (
    <Container>
      <Slider cardCount={8}>
        {trips?.slice(0, 8).map((card, index) => (
          <CardContainer key={index}>
            <Card key={index} src={card?.croppedDestinationImages?.[0]?.preSignedUrl} />
            <Badge>
              <BadgeText>{computeDateAndTimeUntilNowInString(card?.createdAt)} ago</BadgeText>
            </Badge>
          </CardContainer>
        ))}
      </Slider>
      <SliderHeading>
        <h1>
          Discover & Book Your Next Adventure with Travmigoz<span>&deg;</span>
        </h1>
        <p>Find your amigos and travel now!</p>
      </SliderHeading>
    </Container>
  )
}

export default connect(mapStateToProps, null)(memo(SlidingSection))
